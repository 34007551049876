// extracted by mini-css-extract-plugin
export var Collapse = "qr-module--Collapse--Ny-Od";
export var __contentInner = "qr-module--__contentInner--SZB7j";
export var backTop = "qr-module--backTop--EERgk";
export var bar = "qr-module--bar--Z1Q8Q";
export var brainstormMockImg = "qr-module--brainstormMockImg--bjKZ1";
export var brainstormMockImg2 = "qr-module--brainstormMockImg2--+ksaS";
export var brainstormMocks = "qr-module--brainstormMocks--wzO79";
export var brainstorming = "qr-module--brainstorming--11I-d";
export var brainstormingImg = "qr-module--brainstormingImg--WSovo";
export var circleNumber = "qr-module--circleNumber--V5JD7";
export var contributionImg = "qr-module--contributionImg--MmwH9";
export var dropdownItem = "qr-module--dropdownItem--dytHz";
export var dropdownItemFill = "qr-module--dropdownItemFill--HhDKU";
export var dropdownSection = "qr-module--dropdownSection--1gIji";
export var dropdownSectionLast = "qr-module--dropdownSectionLast--8RVW2";
export var findingsContainer = "qr-module--findingsContainer--Y6ycj";
export var findingsDesktop = "qr-module--findingsDesktop--bLNRl";
export var findingsImg = "qr-module--findingsImg--hzU-x";
export var findingsItem = "qr-module--findingsItem--WEF+k";
export var findingsMobile = "qr-module--findingsMobile--Gx0hy";
export var findingsMobileImg = "qr-module--findingsMobileImg--jmB+K";
export var findingsNumber = "qr-module--findingsNumber--pc1AR";
export var findingsText = "qr-module--findingsText--ALAy7";
export var firstMImprovement = "qr-module--firstMImprovement--VF5+V";
export var gifText = "qr-module--gifText--0i7TF";
export var heroImage = "qr-module--heroImage--5SWHN";
export var improvementImg = "qr-module--improvementImg--FPvqf";
export var improvementsContainer = "qr-module--improvementsContainer--IrxUe";
export var indentedContentBelow = "qr-module--indentedContentBelow--Q4kqZ";
export var indentedText = "qr-module--indentedText--ypRUm";
export var isDisabled = "qr-module--is-disabled--ykgE7";
export var locationsImg = "qr-module--locationsImg--GCMXq";
export var mImprovementImg = "qr-module--mImprovementImg--9yaTt";
export var mobileImprovements = "qr-module--mobileImprovements--elISk";
export var mobileImprovementsContainer = "qr-module--mobileImprovementsContainer--A7e0j";
export var ovSubheader = "qr-module--ovSubheader--9RCBp";
export var overviewImg = "qr-module--overviewImg--wkcXl";
export var problemImg = "qr-module--problemImg--2dnWe";
export var prototypeContainer = "qr-module--prototypeContainer--R4lwD";
export var prototypeImg = "qr-module--prototypeImg--Mt0fp";
export var purple1 = "qr-module--purple1--UxyZ1";
export var purple2 = "qr-module--purple2--eWAlP";
export var purple3 = "qr-module--purple3--tN63+";
export var purpleBullet = "qr-module--purpleBullet--pAeDA";
export var purpleText = "qr-module--purpleText--HTvgM";
export var qrWinnerImg = "qr-module--qrWinnerImg--zgHHn";
export var questionsContainer = "qr-module--questionsContainer--CK9zJ";
export var questionsImg = "qr-module--questionsImg--x4Frs";
export var questionsText = "qr-module--questionsText--T+I3l";
export var surveyText = "qr-module--surveyText--VdzWe";
export var takeMeHome = "qr-module--takeMeHome--oaqPF";
export var takeawayItem = "qr-module--takeawayItem--BSfV2";
export var triggerClass = "qr-module--triggerClass--Sb6i-";
export var triggerClassOpen = "qr-module--triggerClassOpen--ortJB";