import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as miCasaStyles from "../styles/micasa.module.css"
import * as qrStyles from "../styles/qr.module.css"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"

import questionsImg from "../assets/qr/questions.png"

import Collapsible from 'react-collapsible'


const QR = () => (
    <main>
        <div id={miCasaStyles.topPageContainer}>
            <Seo title="QR Cup"/>
            <Header home={false}/>
            <section id={miCasaStyles.heroContainer}>
                <div id={miCasaStyles.heroText}>
                        QR Cup
                    <div id={miCasaStyles.heroSubtext}>
                        Closing the convenience gap between using a single-use and reusable cup at coffee shops.
                    </div>
                </div>
                <StaticImage 
                    src="../assets/qr/hero.png"
                    alt="Images of the Mi Casa Mis Derechos art"
                    className={qrStyles.heroImage}
                    quality={100}
                    loading="eager"
                    />
                <div id={miCasaStyles.heroSubtextMobile}>
                    Closing the convenience gap between using a single-use and reusable cup at coffee shops.
                </div>
            </section>
        </div>

        <section id={miCasaStyles.overviewOutterContainer}>
            <div id={miCasaStyles.overviewInnerContainer}>
                        <div class = {miCasaStyles.sectionHeader}>
                            Overview
                        </div>
                        <div id={miCasaStyles.ovSubheadersContainer}>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={qrStyles.ovSubheader}>
                                    Result
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Field-tested interactive<br/>Figma prototype
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={qrStyles.ovSubheader}>
                                    My role
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                UX designer &<br/>researcher
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={qrStyles.ovSubheader}>
                                    The team
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Ollie Hsieh, David Du,<br/>Eileen (Huiyu) Li
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem} id={miCasaStyles.ovSubheaderItemNoMargin}>
                                <div class={qrStyles.ovSubheader}>
                                    Tools
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Figma, Illustrator,<br/> Qualtrics, Miro
                                </div>
                            </div>
                        </div>

                        <div id={miCasaStyles.timelineContainer}>
                            <div class={qrStyles.ovSubheader}>
                                        Timeline
                            </div>
                            <div class={miCasaStyles.ovSubheaderText}>
                                Aug 2019 - Dec 2019
                            </div>
                            <StaticImage 
                                src="../assets/qr/timeline.png"
                                alt="Timeline of project work"
                                className={miCasaStyles.timelineImg}
                                quality={100}
                            />
                        </div>


                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Problem
                            </div>
                            <div class={miCasaStyles.problemContent}>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.problemBody}>
                                    Many people order coffee with a reusable cup out of preference or a desire to avoid single-use plastics. However, <span class={qrStyles.purpleText}>ordering with a reusable cup at a coffee shop is considerably less convenient than using a single-use cup.</span>
                                <br/><br/>
                                    So, how might we close the convenience gap between using a single-use and reusable cup at coffee shops?
                                </div>
                                <StaticImage 
                                        src="../assets/qr/ovFigure.png"
                                        alt="Reasons why single use cups are less convenient than reusable cups in public"
                                        class={qrStyles.problemImg}
                                        placeholder={"blurred"}
                                        quality={100}
                                />
                            </div>
                        </div>
                        
                        <div class={miCasaStyles.sectionContainer2}>
                            <div class={miCasaStyles.pageSubheader}>
                                Solution
                            </div>
                            <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.solutionBody}>
                                We designed QR Cup, <span class={qrStyles.purpleText}>an app that allows users to order and pay automatically with their reusable cups</span> at nearby coffee shops via a QR code.                                
                                <br/><br/>
                                Users print out a QR code linked to the app and place it on their reusable cup. When ordering at a cafe, users simply present their mug to the barista, who scans the QR code to obtain their order and payment details.
                                <br/><br/>
                                The app also tracks usage of the cup and allows the user to set regular reminders for packing and cleaning their cup to reduce cognitive load.
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock} id={miCasaStyles.firstGifBlock}>
                            <div class={qrStyles.gifText}>
                                Let your cup do the talking
                                <div class={miCasaStyles.gifBody}>
                                    QR Cup completely automates the ordering process, providing the barista with both your order and payment information through a printable QR code. It also translates the capacity of your thermos into the store's corresponding size.
                                </div>
                            </div>
                            <div class={miCasaStyles.overviewGif}>
                                <StaticImage 
                                    src="../assets/qr/ov1.png"
                                    alt="QR Cup account screen"
                                    class={qrStyles.overviewImg}
                                    placeholder={"blurred"}
                                    quality={100}
                                />
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock}>
                            <div class={`${miCasaStyles.overviewGif} ${miCasaStyles.orderTwo}`}>
                                <StaticImage 
                                    src="../assets/qr/ov2.png"
                                    alt="QR Cup map screen"
                                    class={qrStyles.overviewImg}
                                    placeholder={"blurred"}
                                    quality={100}
                                />
                            </div>
                            <div class={qrStyles.gifText}>
                                Quickly find nearby cafes
                                <div class={miCasaStyles.gifBody}>
                                    Need your caffeine fix in a hurry? QR Cup takes the guess work out of locating the coffee shop nearest to you. It also provides critical information like business hours and addresses.
                                </div>
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock}>
                            <div class={qrStyles.gifText}>
                                Track your impact
                                <div class={miCasaStyles.gifBody}>
                                    Curious about how many single-use cups you've avoided? Or just how many times you ordered coffee this week? QR Cup automatically tracks and visualizes usage, savings from reusable cup discounts, and your environmental impact.
                                </div>
                            </div>
                            <div class={miCasaStyles.overviewGif}>
                                <StaticImage 
                                    src="../assets/qr/ov3.png"
                                    alt="QR Cup usage tracking screen"
                                    class={qrStyles.overviewImg}
                                    placeholder={"blurred"}
                                    quality={100}
                                />                            
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer} id={miCasaStyles.contributionContainer} >
                            <div class={miCasaStyles.pageSubheader}>
                                My contribution
                            </div>
                            <div id={miCasaStyles.contributionContent}>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.contributionBody}>
                                    With the other designer on the team, <span class={qrStyles.purpleText}>I was responsible for building and designing the app's prototypes from early sketches to wireframes to the final product.</span> I created the design's initial user flow, storyboard, and mockups. Since the project's completion, I have also made some refinements to the prototype on my own.
                                    <br/><br/>
                                    On the research side, I helped develop and distribute online surveys, led user-testing sessions and heuristic evaluations, and distilled our findings into clear, actionable design plans.
                                </div>
                                <StaticImage 
                                        src="../assets/qr/contribution.png"
                                        alt="Three decorative circles"
                                        class={qrStyles.contributionImg}
                                        placeholder={"blurred"}
                                        quality={100}
                                />
                            </div>
                        </div>

            </div> {/* End of overview content div */}
        </section>


        <section id={miCasaStyles.outterDetailsContainer}>
            <div id={miCasaStyles.innerDetailsContainer}>
                <div class = {miCasaStyles.sectionHeader}>
                    Process
                </div>
                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Understanding the problem
                </div>
                <div class={miCasaStyles.bodyText}>
                    To better understand the depth of the problem and the obstacles reusable cup users were facing, we started with two generative research methods: <span class={qrStyles.purpleText}>field observation and surveys.</span>
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple1}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Field observations
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            Online research, such as Starbucks' annual <a href="https://www.starbucks.com/responsibility/global-report" target="_blank" rel="noreferrer">social impact report</a>, confirmed that people overwhelmingly choose to forgo reusable cups. However, we wanted an up-close look at peoples' behavior while ordering, how frequently they chose to use reusable cups (plus what kind), and how ordering systems differed across shops. We teamed up in pairs to observe customers at popular local cafes for sessions lasting about an hour.
                        </div>
                    </div>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Locations visited
                    </div>
                    <div class={miCasaStyles.flexBox}>
                        <StaticImage 
                            src="../assets/qr/locations.png"
                            alt="Locations visited: Starbucks, Galloway Cafe, and Toptime coffee"
                            class={qrStyles.locationsImg}
                            placeholder={"blurred"}
                            quality={100}
                        />
                    </div>

                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Key takeaways
                    </div>
                    <div class={miCasaStyles.flexBox}>
                        <div class={miCasaStyles.marginTop1}>
                            <div class={qrStyles.takeawayItem}>
                                <div class={qrStyles.purpleBullet}/>
                                <div class={miCasaStyles.bodyText}>
                                    Only about 7% of all customers ordered with reusable cups
                                </div>
                            </div>

                            <div class={qrStyles.takeawayItem}>
                                <div class={qrStyles.purpleBullet}/>
                                <div class={miCasaStyles.bodyText}>
                                    A significant portion of customers carried a reusable cup, but did not order with them
                                </div>
                            </div>

                            <div class={qrStyles.takeawayItem}>
                                <div class={qrStyles.purpleBullet}/>
                                <div class={miCasaStyles.bodyText}>
                                    A large share of customers took time-saving measures (ordered ahead, used an app to order/ pay)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple2}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Surveys
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            The observational data was useful in confirming our initial research hypotheses around reusable cup use, but we were lacking real data from users. To remedy this and help frame further research, we developed a Qualtrics survey that asked questions like:  
                        </div>
                    </div>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={miCasaStyles.flexBox}>
                        <div class={qrStyles.questionsContainer}>
                            <img src={questionsImg} alt="Example questions" class={qrStyles.questionsImg}/>
                            <div class={qrStyles.questionsText}>
                                How often do you visit coffee shops (Starbucks, Peet's, etc.)?
                                <br/><br/>
                                If you do use a reusable cup when ordering at a coffee shop, why?
                                <br/><br/>
                                What are some of the challenges you face in carrying and using your reusable cup?
                            </div>
                        </div>
                    </div>

                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Results
                    </div>
                    <div class={`${miCasaStyles.flexBox} ${miCasaStyles.marginTop1}`}>
                        <div>
                            <div class={qrStyles.surveyText}>
                                <div class={miCasaStyles.flex}>
                                    <div class={qrStyles.circleNumber}>
                                        1
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        The most cited obstacles were: "It's inconvenient to pack a personal cup" and "I'm not sure if I can use my personal cup at coffee shops"
                                    </div>
                                </div>
                            </div>
                            <div class={qrStyles.surveyText}>
                                <div class={miCasaStyles.flex}>
                                    <div class={qrStyles.circleNumber}>
                                        2
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        Participants said that they ordered with reusable cups to save money, eliminate waste, and out of preference
                                    </div>
                                </div>
                            </div>

                            <div class={qrStyles.surveyText}>
                                <div class={miCasaStyles.flex}>
                                    <div class={qrStyles.circleNumber}>
                                        3
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        More than 50% of participants said that reducing single-use plastics is very or extremely important to them
                                    </div>
                                </div>
                            </div>


                            <div class={qrStyles.surveyText}>
                                <div class={miCasaStyles.flex}>
                                    <div class={qrStyles.circleNumber}>
                                        4
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        While the majority of respondents owned a reusable cup, only 5% said they said they ordered coffee with them
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Scoping down
                </div>
                <div class={miCasaStyles.bodyText}>
                    With a stronger grasp on the common pain points users were having, we felt confident that we could proceed with <span class={qrStyles.purpleText}>meaningful in-depth interviews</span> with reusable cup owners.
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple3}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Semi-structured interviews
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            We conducted 5 semi-structured interviews with folks who own reusable cups to learn more about their motivations, drill down into their anxieties about ordering with them, and discuss features they might want to see in a new product. Conversations lasted 45 minutes - 1 hour, and took place around midtown Atlanta.
                        </div>
                    </div>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Affinity map
                    </div>
                </div>

                <div class={miCasaStyles.flexBox}>
                    <a href="https://miro.com/app/board/o9J_kwHrW1g=/" target="_blank" rel="noreferrer">
                        <StaticImage 
                            src="../assets/qr/affinitymap.png"
                            alt="Affinity mapping"
                            placeholder={"blurred"}
                            quality={100}
                        />
                    </a>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.bodyText} ${qrStyles.indentedText} ${miCasaStyles.marginTop4}`}>
                        After all interviews were complete, we set down as a team and constructed an affinity diagram (above) to distill common themes across participants.
                    </div>

                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Findings
                    </div>

                    <div class={miCasaStyles.flexBox}>

                        <div class={qrStyles.findingsContainer}>
                            <div class={qrStyles.findingsItem}>
                                <div class={qrStyles.findingsNumber}>
                                    1
                                </div>
                                <div class={qrStyles.findingsText}>
                                    Users sometimes forget their reusable cup at home or lose it while out
                                </div>
                            </div>

                            <div class={qrStyles.findingsItem}>
                                <div class={qrStyles.findingsNumber}>
                                    2
                                </div>
                                <div class={qrStyles.findingsText}>
                                    Users find storing their reusable cup inconvenient and stressful
                                </div>
                            </div>

                            <div class={qrStyles.findingsItem}>
                                <div class={qrStyles.findingsNumber}>
                                    3
                                </div>
                                <div class={qrStyles.findingsText}>
                                    Users are anxious about ordering with their reusable cup because they don't know what size to tell the barista
                                </div>
                            </div>

                            <div class={qrStyles.findingsItem}>
                                <div class={qrStyles.findingsNumber}>
                                    4
                                </div>
                                <div class={qrStyles.findingsText}>
                                    Users felt cleaning their reusable cup after use is inconvenient 
                                </div>
                            </div>

                            <div class={qrStyles.findingsItem}>
                                <div class={qrStyles.findingsNumber}>
                                    5
                                </div>
                                <div class={qrStyles.findingsText}>
                                    Participants felt like there was not enough incentive to use a reusable cup
                                </div>
                            </div>

                            <div class={qrStyles.findingsItem}>
                                <div class={qrStyles.findingsNumber}>
                                    6
                                </div>
                                <div class={qrStyles.findingsText}>
                                    Participants generally felt motivated to decrease their use of single-use plastics
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div id={qrStyles.brainstorming}>
                    <div>
                        <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                            Brainstorming and ideation
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            <div>
                                With our initial research complete, <span class={qrStyles.purpleText}>we sketched out design implications from our research findings and brainstormed possible design solutions</span> as a group. The focus was on generating as many creative solutions as possible -- without judgment on their quality. We then sorted design solutions under different umbrellas, discussed potential pros and cons, sought evidence from research, and finally ranked them.
                                <br/><br/>
                                The result was two robust ideas that we wanted to explore further.
                            </div>
                        </div>
                    </div>
                        <StaticImage 
                            src="../assets/qr/brainstorming.png"
                            alt="Idea 1: QR Cup; Idea 2: Mugshare program"
                            class={qrStyles.brainstormingImg}
                            placeholder={"blurred"}
                            quality={100}
                        />   
                        
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple1}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            QR Cup
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            This concept allows users to order and pay automatically with their reusable cup at coffee shops via a QR code. The user would download an app on their phone, enter their drink of choice, personal information, and payment details, then be able to automate the checkout process at cafes. The app would also include reminders to pack and clean their cup, and track their usage so they can better understand their savings and environmental impact.
                        </div>
                    </div>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Initial concept
                    </div>

                    <div class={qrStyles.brainstormMocks}>
                        <StaticImage 
                            src="../assets/qr/qr_mock1.png"
                            alt="A reusable cup with a qr code linked to an app on your phone"
                            class={qrStyles.brainstormMockImg}
                            placeholder={"blurred"}
                            quality={100}
                        />   
                        <StaticImage 
                            src="../assets/qr/qr_mock2.png"
                            alt="A phone screen that displays how you would order with your reusable cup"
                            class={qrStyles.brainstormMockImg}
                            placeholder={"blurred"}
                            quality={100}
                        />  
                    </div>
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={`${miCasaStyles.indentedInner} ${miCasaStyles.marginTop3}`}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple2}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Mugshare program
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            A mugshare program is a cup exchange system wherein local cafes provide reusable cups for drink orders (as well as incentives for using them), and allow users to return the mugs at any of the participating stores. The system typically entails a deposit to prevent theft and offloads responsibility of washing cups to the venues.
                            <br/><br/>
                            For this concept, we were interested in designing the mobile app that would allow users to quickly and easily rent out and return cups in the mugshare program. We had two versions of this idea that we wanted to explore: (A) allowing users to select and return mugs through existing cafe apps and (B) a completely new, standalone application that would manage a mugshare program that cafes could to opt into.
                        </div>
                    </div>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop4}`}>
                        Initial concept
                    </div>

                    <div class={qrStyles.brainstormMocks}>
                        <StaticImage 
                            src="../assets/qr/mugshare1.jpg"
                            alt="Mugshare option 1: a solution built into existing stores applications"
                            class={qrStyles.brainstormMockImg2}
                            placeholder={"blurred"}
                            quality={100}
                        />   
                        <StaticImage 
                            src="../assets/qr/mugshare2.jpg"
                            alt="Mughsare option 2: a new standalone application for a complete mugshare program"
                            class={qrStyles.brainstormMockImg2}
                            placeholder={"blurred"}
                            quality={100}
                        />  
                    </div>
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Finalizing the idea
                </div>
                <div class={miCasaStyles.bodyText}>
                    With two feasible ideas on the table, we were now left with the task of choosing one and fleshing it out into something we could fully prototype.
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple3}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Selecting a winner
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            To gather high-level feedback, we presented our project ideas to other MS Human Computer Interaction students as well as faculty.<span class={qrStyles.purpleText}> We had the students and faculty rate the designs on three criteria: feasibility, novelty, and how much it solves the problem.</span> Idea 1 (the QR Cup) took first place in feasibility and novelty, while idea 2 (mugshare program) slightly edged out the QR Cup for first in how well it solved the problem.
                            <br/><br/>
                            With this feedback in hand, we then conducted an internal review of the two deisgns, rating each on the same criteria.<span class={qrStyles.purpleText}> After some internal discussion and further review of the research we had condcted, we chose to advance the QR Cup.</span> While we all agreed that the mugshare program may have a larger impact due to its scale, implementing it within the scope of this project would have been difficult -- hinging on cooperation from coffee shops. On the other hand, the QR Cup design was incredibly feasible, more novel than a mugshare program (which are rare, but exist), and still solves critical problems revealed through our research.
                        </div>
                    </div>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={miCasaStyles.flexBox}>
                        <StaticImage 
                            src="../assets/qr/qrWinner.png"
                            alt="QR Cup with a winner's trophy next to it"
                            class={qrStyles.qrWinnerImg}
                            placeholder={"blurred"}
                            quality={100}
                        />  
                    </div>
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple1}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Crystalizing the concept
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            Before beginning work on the QR Cup prototype, we wanted to fully flesh out the concept and create a shared understanding of what the user experience should look like. To do so, we locked in the app's core features (and justified them against research).
                        </div>
                    </div>
                </div>

                <div class={qrStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Core features and justification
                    </div>

                    <div class={qrStyles.dropdownSection} style={{marginTop: "2rem"}}>
                        <div class={qrStyles.dropdownItem}>
                            <Collapsible 
                                trigger="1. Automated ordering"
                                classParentString={qrStyles.Collapse}
                                contentInnerClassName={qrStyles.__contentInner}
                                triggerClassName={qrStyles.triggerClass}
                                triggerOpenedClassName={qrStyles.triggerClassOpen}
                                transitionTime={275}
                            >
                                    <span class={miCasaStyles.bodyText}>Expediency while ordering was key with the users we talked to in interviews and observed. Whether they are running to class or need to meet a deadline, users want to be able to order their coffee and exit the cafe in short order. Allowing users to order and pay automatically is simply faster. </span> 
                                    <br/><br/>
                                    <span class={miCasaStyles.bodyText}>Further, the largest reason users cited for avoided using a reusable cup in our survey was a lack of convenience. Enabling a user's cup to pay and order for them directly boosts their convenience, putting them more on par with ordering via a mobile app in advance.</span> 

                            </Collapsible>
                        </div>

                        <div class={qrStyles.dropdownItem}>
                            <Collapsible 
                                trigger="2. SMS reminders"
                                classParentString={qrStyles.Collapse}
                                contentInnerClassName={qrStyles.__contentInner}
                                triggerClassName={qrStyles.triggerClass}
                                triggerOpenedClassName={qrStyles.triggerClassOpen}
                                transitionTime={275}
                            >
                                <span class={miCasaStyles.bodyText}>A large pain point across the user interviews was the forgetfulness of users to clean or pack their thermos for the day. Allowing the users to set dedicated reminders for themselves in the app reduces their cognitive load, placing it instead on the phone.</span> 

                            </Collapsible>
                        </div>

                    </div>

                    <div class={qrStyles.dropdownSection}>

                        <div class={qrStyles.dropdownItem}>
                            <Collapsible 
                                trigger="3. Auto size conversion"
                                classParentString={qrStyles.Collapse}
                                contentInnerClassName={qrStyles.__contentInner}
                                triggerClassName={qrStyles.triggerClass}
                                triggerOpenedClassName={qrStyles.triggerClassOpen}
                                transitionTime={275}
                            >
                                <span class={miCasaStyles.bodyText}>Several interview participants and survey respondents said that they felt anxious about ordering with a reusable cup due to a lack of knowledge of what size their thermos would be at the cafe. Automatically converting their thermos size to the venue they select allows them to be confident that their drink will be the appropriate size.</span> 

                            </Collapsible>
                        </div>

                        <div class={qrStyles.dropdownItem}>
                            <Collapsible 
                                trigger="4. Usage tracking"
                                classParentString={qrStyles.Collapse}
                                contentInnerClassName={qrStyles.__contentInner}
                                triggerClassName={qrStyles.triggerClass}
                                triggerOpenedClassName={qrStyles.triggerClassOpen}
                                transitionTime={275}
                            >
                                <span class={miCasaStyles.bodyText}>Users noted that they want to reduce single-use plastics but often don’t feel motivated to do so. By visualizing the amount of cups they have avoided using, users can better see their environmental impact. Survey respondents indicated that a large motivation for using a reusable cup was to save money from incentives, so we wanted to track and visualize that data as well.</span> 

                            </Collapsible>
                        </div>

                    </div>

                    <div class={qrStyles.dropdownSection}>
                        <div class={qrStyles.dropdownItem}>
                            <Collapsible 
                                trigger="5. Printable QR code"
                                classParentString={qrStyles.Collapse}
                                contentInnerClassName={qrStyles.__contentInner}
                                triggerClassName={qrStyles.triggerClass}
                                triggerOpenedClassName={qrStyles.triggerClassOpen}
                                transitionTime={275}
                            >
                                <span class={miCasaStyles.bodyText}>Multiple interviewees noted that they easily lose or damage their reusable cups. An easily printable QR code allows users to retrofit their existing thermos without spending virtually any money, and if lost or damaged can be easily replaced. </span> 

                            </Collapsible>
                        </div>

                        <div class={qrStyles.dropdownItemFill}/>

                    </div>

                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Prototype
                </div>
                <div class={miCasaStyles.bodyText}>
                    To collaboratively construct a testable prototype of the mobile application,<span class={qrStyles.purpleText}> we decided to build the system in Figma.</span> Figma allowed us to both create high-fidelity mockups of the QR Cup application and build in interaction (clicking, swiping) that we could test with users. For the QR code, we used an online QR code generator that we can customize and program to point to a dummy address, and printed off a test QR code to simulate scanning it on a reusable cup.
                </div>

                <div class={`${qrStyles.prototypeContainer} ${miCasaStyles.marginTop5}`}>
                    <StaticImage 
                        src="../assets/qr/screen1.png"
                        alt="App homescreen"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    />  
                    <StaticImage 
                        src="../assets/qr/screen2.png"
                        alt="App purchases screen"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    />  
                    <StaticImage 
                        src="../assets/qr/screen3.png"
                        alt="App account screen"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                    <StaticImage 
                        src="../assets/qr/screen4.png"
                        alt="App account screen: adding a new cup"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                </div>

                <div class={qrStyles.prototypeContainer}>
                    <StaticImage 
                        src="../assets/qr/screen5.png"
                        alt="App homescreen"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    />  
                    <StaticImage 
                        src="../assets/qr/screen6.png"
                        alt="App purchases screen"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    />  
                    <StaticImage 
                        src="../assets/qr/screen7.png"
                        alt="App account screen"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                    <StaticImage 
                        src="../assets/qr/screen8.png"
                        alt="App account screen: adding a new cup"
                        class={qrStyles.prototypeImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Testing and iteration
                </div>
                <div class={miCasaStyles.bodyText}>
                    To gather feedback and identify areas of improvement for our prototype,<span class={qrStyles.purpleText}> we conducted both expert and user-based testing.</span> I find this pair of testing to be complementary: expert-based testing allowed us to identify usability issues according to recognized usability specifications and best practices, while user-based testing permitted us to assess how well actual users are able to complete tasks important to them and obtain feedback in their voice.
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple2}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Expert-based testing
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            We conducted 3 heuristic evaluations with experts in the UX field, asking each participant to assess the prototype based on <a href="https://www.cs.umd.edu/users/ben/goldenrules.html" target="_blank" rel="noreferrer">Shneiderman’s Eight Golden Rules</a> of interface design. Each session began with an overview of the eight golden rules as well as the prototype, and lasted in total from 60 - 90 minutes; participants were encouraged to think aloud as they went through the evaluation.
                            <br/><br/>
                            The heuristic evaluations uncovered glaring problems with wayfinding in the prototype, which we worked to address before moving on to user testing to avoid getting repetitive feedback.
                        </div>
                    </div>
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple3}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            User-based testing
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            We recruited four students at Georgia Tech to participate in usability testing of the prototype. To test the design, we developed six benchmark tasks for each participant to attempt, and asked them to rate each task after completion using a <a href="https://humansystems.arc.nasa.gov/groups/tlx/downloads/TLXScale.pdf" target="_blank" rel="noreferrer">NASA TLX form</a>. Since wayfinding was an issue identified in our heuristic evaluations, we also tracked the amount of time spent on each screen during tasks, as well as the number of screens navigated to. With this data, we used <a href="https://www.sciencedirect.com/science/article/abs/pii/S0953543897837794" target="_blank" rel="noreferrer">Smith's formula</a> to calculate objective lostness.
                        </div>
                    </div>
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${qrStyles.bar} ${qrStyles.purple1}`}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Results
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            To analyze the data, we gathered as a team and coded our notes for emerging usability themes. Through rigorous internal discussion, we then distilled these into actionable research findings, grouping items based on whether they were identified by experts, users, or both.
                        </div>
                    </div>
                </div>

                <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Key findings
                </div>

                <div class={`${miCasaStyles.flexBox} ${miCasaStyles.marginTop3}`} id={qrStyles.findingsDesktop}>
                    <StaticImage 
                        src="../assets/qr/findings.png"
                        alt="Key findings"
                        class={qrStyles.findingsImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                </div>

                <div id={qrStyles.findingsMobile}>
                    <StaticImage 
                        src="../assets/qr/findings1.png"
                        alt="Key findings"
                        class={qrStyles.findingsMobileImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                    <StaticImage 
                        src="../assets/qr/findings3.png"
                        alt="Key findings"
                        class={qrStyles.findingsMobileImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                    <StaticImage 
                        src="../assets/qr/findings2.png"
                        alt="Key findings"
                        class={qrStyles.findingsMobileImg}
                        placeholder={"blurred"}
                        quality={100}
                    /> 
                </div>

                <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Prototype improvements
                </div>

                <div class={qrStyles.improvementsContainer}>
                    <StaticImage 
                            src="../assets/qr/improvement1.png"
                            alt="Improvement 1"
                            class={qrStyles.improvementImg}
                            placeholder={"blurred"}
                            quality={100}
                        /> 
                        <StaticImage 
                            src="../assets/qr/improvement2.png"
                            alt="Improvement 2"
                            class={qrStyles.improvementImg}
                            placeholder={"blurred"}
                            quality={100}
                        /> 
                        <StaticImage 
                            src="../assets/qr/improvement3.png"
                            alt="Improvement 3"
                            class={qrStyles.improvementImg}
                            placeholder={"blurred"}
                            quality={100}
                        /> 
                </div>

                <div id={qrStyles.mobileImprovementsContainer}>

                    <div class={qrStyles.mobileImprovements} id={qrStyles.firstMImprovement}>
                        <StaticImage 
                            src="../assets/qr/mImprovement1.png"
                            alt="Improvement 1"
                            class={qrStyles.mImprovementImg}
                            placeholder={"blurred"}
                            quality={100}
                        /> 
                    </div>

                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop3}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            Increased font size and UI components
                        </div>
                    </div>
                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop1}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            Quick change option allows users to easily change order from the homescreen
                        </div>
                    </div>
                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop1}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            New order flow to match user expectation: location comes before ordering
                        </div>
                    </div>
                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop1}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            Standardized drop downs across the app
                        </div>
                    </div>
                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop1}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            Added $ to order history
                        </div>
                    </div>

                    <div class={qrStyles.mobileImprovements}>
                        <StaticImage 
                            src="../assets/qr/mImprovement2.png"
                            alt="Improvement 2"
                            class={qrStyles.mImprovementImg}
                            placeholder={"blurred"}
                            quality={100}
                        /> 
                    </div>

                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop3}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            Filtering icon red when feature in use; displays current filtering selection
                        </div>
                    </div>

                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop1}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            Submit button added to provide clarity around order updating
                        </div>
                    </div>

                    <div class={qrStyles.mobileImprovements}>
                        <StaticImage 
                            src="../assets/qr/mImprovement3.png"
                            alt="Improvement 3"
                            class={qrStyles.mImprovementImg}
                            placeholder={"blurred"}
                            quality={100}
                        /> 
                    </div>

                    <div class={`${miCasaStyles.flex} ${miCasaStyles.marginTop3}`}>
                        <div class={qrStyles.purpleBullet}/>
                        <div class={miCasaStyles.bodyText}>
                            New security feature: the app confirms purchases before allowing transaction to process
                        </div>
                    </div>
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Reflection
                </div>
                <div class={miCasaStyles.bodyText}>
                    I had a blast working on this project! Our team had a strong mix of folks with experience in design, research, and development – which went a long way in improving the quality of the work.
                    <br/><br/>
                    Something that really worked for us was our project management; we had daily standups during the semester that allowed us to identify roadblocks, ask questions, and check in on progress. We also used basecamp to share files, create to-dos, and directly collaborate on work, which I found highly effective.
                    <br/><br/>
                    This project also made me interrogate the limits of technology in addressing a user's problems – and how to be creative to get around those limitations. Many of the users we spoke to in our research flagged issues that were material in nature: inconvenience cleaning and carrying their reusable cup, a lack of infrastructure to clean their reusable cups in public, etc. As a team, we wanted to address these problems with our design in a way that was not artificially using tech when other simpler solutions could be more effective. As a result, we identified the areas of these problems that we knew we could be effective at addressing, most notably the cognitive load of the user. That's why we added automatic reminders for carrying and cleaning the cup as a core feature of QR Cup.
                </div>

                <div id={miCasaStyles.buttonsContainer} class={`${miCasaStyles.flexBox} ${miCasaStyles.marginTop7}`} style={{paddingBottom: "1rem"}}>
                    <Link to="/" class={miCasaStyles.linkButton}>
                        <div id={qrStyles.takeMeHome} class={miCasaStyles.botButton}>
                            Take me home
                        </div>
                    </Link>
                    <Link to={`/qr-cup#${miCasaStyles.topPageContainer}`}>
                        <div id={qrStyles.backTop} class={miCasaStyles.botButton}>
                            Back to top
                        </div>
                    </Link>
                </div>


            </div> {/* End of details content div */}
        </section>

        <Footer/>
    </main>
)

export default QR